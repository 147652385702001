<template>
  <base-layout page-default-back-link="/tabs/menu">
     <ion-header>{{item.name}}</ion-header>
     <ion-img :src="item.image" alt="Picture unavailable"></ion-img>
     <ion-button>Add to order</ion-button>
  </base-layout>
</template>

<script>
//import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
//import { IonImg, IonHeader, IonButton } from '@ionic/vue';
export default  {
  name: 'MenuItemDetails',
  //components: { IonImg, IonHeader, IonButton },
  data() {
    return {
      item: this.$route.params.item,
    };
  },
  
};
</script>